
import { defineComponent, reactive, toRefs } from 'vue';
import YImage from '@/components/modules/image/index.vue';

import { homeStatistics } from '@/api/common';
import _ from 'lodash';

export default defineComponent({
  components: { YImage },
  name: 'Workbench',
  setup() {
    const retData = reactive({
      Total: {
        AccessCount: 0,
        NpcCount: 0,
        OrderCount: 0,
        ShopCount: 0,
        UserCount: 0,
      },
      Today: {
        AccessCount: 0,
        NpcCount: 0,
        OrderCount: 0,
        ShopCount: 0,
        UserCount: 0,
      },
      ShopRank: [],
      NpcRank: [],
    });
    homeStatistics().then((ret: any) => {
      _.assign(retData, ret.data);
    });

    return {
      ...toRefs(retData),
      shopColumns: [
        { title: '店铺头像', dataIndex: 'ShopHeadImg', key: 'ShopHeadImg', slots: { customRender: 'avatar' } },
        { title: '店铺名称', dataIndex: 'ShopName', key: 'ShopName' },
        { title: '访问次数', dataIndex: 'RecordCount', key: 'RecordCount' },
      ],
      columns: [
        { title: '头像', dataIndex: 'NpcAvatar', key: 'NpcAvatar', slots: { customRender: 'avatar' } },
        { title: '名称', dataIndex: 'NpcName', key: 'NpcName' },
        { title: '访问次数', dataIndex: 'RecordCount', key: 'RecordCount' },
      ],
    };
  },
});
