import request from './axios';

// oss上次图片获取token
export const getOssToken = (path: string): Promise<any> => {
  return request({
    url: '/common/oss/upload',
    method: 'post',
    data: { path },
  });
};

//首页统计
export const homeStatistics = (): Promise<any> => {
  return request({
    url: '/manage/index/count',
    method: 'get',
  });
};
