
import { MenuUnfoldOutlined, MenuFoldOutlined, CloseOutlined, RedoOutlined, MoreOutlined } from '@ant-design/icons-vue';
import Menu from './menu.vue';
import { useStore } from 'vuex';
import { defineComponent, ref, reactive, computed, nextTick } from 'vue';
import { Tabs, Divider, message, Dropdown, Modal } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useInitRouter } from '@/util/router';
import storage from 'store';
import { awaitWrap } from '@/util/index';
import { loginout, passwordUpdate } from '@/api/user';

export default defineComponent({
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    CloseOutlined,
    RedoOutlined,
    MoreOutlined,
    Menu,
    AModal: Modal,
    ADropdown: Dropdown,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ADivider: Divider,
  },
  setup() {
    const routerState = ref(true);
    const refreshSpin = ref(false);
    const store = useStore();
    const router = useRouter();
    let activeKey = ref(0);
    const { routerPush } = useInitRouter();

    const userInfo = reactive({
      Address: '',
      HeadImg: 'http://um.file.huayixh.com/pc/boy.png',
      ShopName: '超级管理员',
      ShopCode: '',
    });

    //退出登录
    function handleExit() {
      loginout();
      store.commit('empty', {});
      storage.remove('ACCESS_TOKEN');
      routerPush('/login');
    }

    const tabUrls = computed(() => {
      store.state.urls.forEach((element: any) => {
        if (element.selected) {
          activeKey.value = element.url;
        }
      });
      return store.state.urls;
    });
    const keepAliveViews = computed(() => {
      return store.getters.keepAliveViews;
    });

    const tabChange = (activeKey: string) => {
      router.push(activeKey);
    };
    const tabRefresh = async (index: number) => {
      let nowUrl = tabUrls.value[index];
      let isKeepAlive = nowUrl.keepAlive;
      console.log(nowUrl);
      refreshSpin.value = true;
      if (isKeepAlive) {
        store.commit('refreshUrl', {
          index: index,
          keepAlive: false,
        });
      }

      routerState.value = false;
      await nextTick();
      routerState.value = true;

      if (isKeepAlive) {
        store.commit('refreshUrl', {
          index: index,
          keepAlive: true,
        });
      }

      setTimeout(() => {
        refreshSpin.value = false;
      }, 1000);
    };
    const tabRemove = (index: number) => {
      let nowUrl = tabUrls.value[index];
      store.commit('delUrl', {
        index: index,
      });
      //如果关闭的页面是当前选中的页面，就需要跳转到上一个标签页
      if (nowUrl.selected) {
        router.push(tabUrls.value[index - 1].url);
      }
    };

    let passwordUpdateModel = ref(false);
    const passwordInfo = reactive({
      NewPassword: '',
      OldPassword: '',
      SurePassword: '',
    });
    // passwordUpdate
    function handleUpdate() {
      passwordUpdateModel.value = true;
    }

    async function handleOkUpdate() {
      const { code } = await awaitWrap(passwordUpdate(passwordInfo));
      if (code === 200) {
        message.success('添加成功');
        routerPush('/login');
      }
    }
    return {
      routerState,
      passwordUpdateModel,
      handleUpdate,
      passwordInfo,
      handleOkUpdate,
      refreshSpin,
      userInfo,
      handleExit,
      selectedKeys: ref(['1']),
      collapsed: ref(false),
      tabUrls,
      activeKey,
      tabRemove,
      tabChange,
      tabRefresh,
      keepAliveViews,
    };
  },
});
